import { useEffect, useState } from 'react';
import { auth, db } from '../firebase/config'
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import ModuleBox from '../components/ModuleBox';
import Footer from '../components/Footer';

import { 
  FaGoogleDrive, 
  FaShieldAlt, 
  FaCookieBite, 
  BsClipboard2DataFill,
  PiChalkboardTeacherFill,
  MdInventory
} from '../assets/icons/Icons.js';

const Main = () => {
  const navigate = useNavigate()

  const [data, setData] = useState({ lgpdrive: '', inventario: '', riscos: '', dados: '', cookies: '', treinamentos: '' })
  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login')
      return
    }

    const userId = auth.currentUser.uid
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'user-data', userId)
        const result = await getDoc(docRef)

        if (result.exists()) {
          setData(result.data())
        }
      } catch (e) {
        console.error(e)
      }
    }

    fetchData();
  }, [navigate])

  const modules = [
    {
      title: "LGPDrive",
      description: 
        `
          Protege acervos documentais físicos por meio da transformação digital, criptografia,
          buscas inteligentes e anonimização de dados pessoais, garantindo segurança e
          conformidade à LGPD.
        `,
      Icon: FaGoogleDrive,
      redirect: data.lgpdrive,
    },
    {
      title: "Inventário e Mapeamento de Dados",
      description: 
      `
        Identifica e registra as atividades de tratamento, oferecendo uma visão abrangente do
        ciclo de vida dos dados pessoais na Entidade Pública
      `,
      Icon: MdInventory,
      redirect: data.inventario,
    },
    {
      title: "Gestão de Riscos",
      description: 
      `
        Avalia e monitora os riscos associados ao tratamento de dados pessoais, seguindo normas
        de padronização internacional e propondo ações para mitigar vulnerabilidades e otimizar
        a segurança da informação.
      `,
      Icon: FaShieldAlt,
      redirect: data.riscos,
    },
    {
      title: "Gestão de Dados",
      description: 
      `
        Um canal de comunicação eficiente para gerir os requerimentos dos titulares de dados,
        facilitando o pleno exercício dos direitos previstos na LGPD e promovendo a
        transparência e o atendimento ágil às demandas de privacidade
      `,
      Icon: BsClipboard2DataFill,
      redirect: data.dados,
    },
    {
      title: "Gestão de Cookies",
      description:
      `
        Configura e gerencia o uso de cookies em sites e aplicativos, permitindo personalização
        e conformidade com normas de consentimento
      `,
      Icon: FaCookieBite,
      redirect: data.cookies,
    },
    {
      title: "Treinamentos",
      description: 
      `
        Oferece trilhas de capacitação sobre proteção de dados e segurança da informação,
        promovendo a conscientização e a formação da Cultura de Privacidade aos agentes
        públicos
      `,
      Icon: PiChalkboardTeacherFill,
      redirect: data.treinamentos,
    },
  ];

  return (
    <div className='min-h-screen flex flex-col'>

      <div className='flex-grow'>
        <Header />
        <section className='mt-3 py-3 px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex-grow'>
          {modules.map((module, index) => (
            <ModuleBox
              key={index}
              title={module.title}
              description={module.description}
              Icon={module.Icon}
              redirect={module.redirect}
            />
          ))}
        </section>
      </div>

      <Footer />
    </div>
  );

}

export default Main;
