const ModuleBox = ({ title, description, Icon, redirect }) => {
  
  const boxStyle = `
   ${redirect ? '' : 'hidden'} bg-black bg-opacity-55 px-4 lg:px-2 rounded-lg h-80 text-white flex flex-col items-center justify-center gap-4 cursor-pointer
    hover:bg-opacity-75 
  `
  
  return (
    <div 
      className={boxStyle}
      onClick={() => window.open(redirect)}
    >
      {<Icon className='h-[45px] w-[45px] lg:h-[55px] lg:w-[55px]' />}
      <div className='bg-white h-[1px] w-full max-w-[80%]'></div> 
      <h1 className='text-lg lg:text-xl font-black text-center'>{title}</h1>
      <p className='px-2 text-sm text-center min-h-[60px] max-h-[60px]'>{description}</p>
    </div>
  );
};

export default ModuleBox;
