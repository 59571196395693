import { auth } from '../firebase/config'
import { useNavigate } from 'react-router-dom';
import { FiLogOut, FaLaptop } from '../assets/icons/Icons.js'

const Header = () => {
  const navigate = useNavigate()

  const handleLogOut = () => {
    auth.signOut();
    navigate('/login')
  }

  return (
    <header className='flex items-center justify-between gap-3 w-full bg-black bg-opacity-50 h-20 text-center text-sm md:text-xl lg:text-2xl text-white font-sans font-semibold px-1 md:px-4'>
      <div className='flex items-center gap-3 ml-5'>
        <FaLaptop className='h-[20px] w-[20px] md:h-[32px] md:w-[32px]' />
        <h1>Plataforma de Gestão e Governança de Dados</h1>
      </div>

      <div
        className='mr-1 lg:mr-2 hover:text-red-600 hover:bg-black hover:bg-opacity-5 p-2 lg:p-3 rounded-md cursor-pointer'
        onClick={handleLogOut}
      >
        <FiLogOut className='h-[20px] w-[20px] md:h-[26px] md:w-[26px]' />
      </div>
    </header>
  )

}

export default Header
