import { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from '../assets/icons/Icons.js'


const Input = ({type, label, value, onChange, onError}) => {
  const [showPassword, setShowPassword] = useState(false)

  const inputStyle = `block mx-auto h-10 md:h-12 p-4 ${label !== 'Senha' ? 'w-full rounded-xl' : 'w-90/100 rounded-s-xl'}`
  const showPasswordButtonIconStyle = 'md:h-[22px] md:w-[22px] h-[18px] w-[18px]'
  const showPasswordButtonStyle = `w-10/100 flex items-center justify-center text-slate-400 ${label !== 'Senha' ? 'hidden' : ''}`

  const handleChange = (e) => {
    onChange(e.target.value)
    onError(false)
  }

  return(
    <div className='bg-white flex mb-8 rounded-xl max-w-lg mx-auto w-full'>
      
      <input
        type={showPassword ? 'text' : type}
        aria-label={label}
        value={value}
        onChange={(e) => handleChange(e)}
        placeholder={label}
        className={inputStyle}
      />
      
      <div 
        className={showPasswordButtonStyle}
        onClick={() => setShowPassword(!showPassword)}
        >
        {
          showPassword ? 
            <FaRegEyeSlash className={showPasswordButtonIconStyle} /> : 
            <FaRegEye className={showPasswordButtonIconStyle} />
        }
      </div>
    </div>
  )

}

export default Input