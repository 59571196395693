import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { 
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Main from './pages/Main';
import Login from './pages/Login';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  }, 
  {
    path: '/login',
    element: <Login />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);