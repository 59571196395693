import logo from '../assets/logo.png'
import { TbWorldWww, AiFillInstagram, IoMailSharp} from '../assets/icons/Icons.js'


const ContactInfo = ({text, Icon}) => {
  return(
    <div className='flex items-center gap-1 mb-1'>
      <Icon size={12}/>
      <p>{text}</p>
    </div>
  )
}

const Footer = () => {
  return (
    <footer className='
      text-xs md:text-sm h-36 md:h-40 lg:h-42 py-2 px-4 bg-black bg-opacity-50 grid gric-cols-1
     text-white text-opacity-75
     '
    >
      
      <div className='lg:w-[60%] lg:mx-auto'>
        <div className='p-2'>
          <h1 className='lg:text-center'>Copyright &copy; 2024 — Neogov. Todos os direitos reservados</h1>
          <div className='h-[0.5px] mt-2 bg-white bg-opacity-35'></div>
        </div>
        <div className='px-2 flex justify-around'>
          <figure className='flex items-center justify-center'>
              <img src={logo} alt='logo' className=' h-8 md:h-10' />
          </figure>
          <div>
            <h1 className='font-semibold mb-2'>Entre em contato:</h1>
            <ContactInfo
              text='neogov.com.br'
              Icon={TbWorldWww}
            />
            <ContactInfo
              text='contato@neogov.com.br'
              Icon={IoMailSharp}
            />
            <ContactInfo
              text='@neogovofic'
              Icon={AiFillInstagram}
            />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;