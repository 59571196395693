import { useState } from 'react'
import logo from '../assets/logo.png'
import Input from '../components/Input'
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { FiLogIn } from '../assets/icons/Icons.js';
import { useNavigate } from 'react-router-dom'


const Login = () => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();
    try{
      await signInWithEmailAndPassword(auth, username.trim(), password.trim())
      navigate('/')
    } catch(e) {
      setError(true)
    }
  }

  return (
    <div className='h-full flex items-center'>

      <div className=' w-full h-4/5'>
        <form className='flex flex-col justify-center bg-black bg-opacity-30 w-11/12 max-w-3xl h-95/100 min-h-[630px] mx-auto py-8 px-4 lg:pt-10 rounded-xl'>
          
          <figure className='mb-12'>
            <img src={logo} alt='logo' className='mx-auto scale-75' />
          </figure>

          <Input type='text' label='E-mail' value={username} onChange={setUsername} onError={setError}/>
          <Input type='password' label='Senha' value={password} onChange={setPassword} onError={setError}/>

          {error && <p className='text-center text-red-500 font-black'>Erro ao entrar, verifique o e-mail e a senha.</p>}

          <div className='flex bg-teal-600 h-10 md:h-12 mt-8 w-54 md:w-60 rounded-xl mx-auto text-white text-xl md:text-xl font-black'>
            <button className='flex items-center justify-center gap-2 mx-auto h-full py-2 px-12' onClick={(e) => handleLogin(e)}>
              Entrar
              <FiLogIn size={25} />
            </button>
          </div>
        </form>

      </div>


    </div>
  )

}

export default Login;